<template>
  <div class="background overflow-auto" :style="cssProps">
    <!-- <Nav /> -->
    <ComingSoon />
  </div>
</template>

<script>
// import Nav from "./components/Nav.vue";
import ComingSoon from "./pages/ComingSoon";

export default {
  name: "App",
  components: {
    ComingSoon
  },
  data() {
    return {
      cssProps: {
        height: "100%",
        backgroundImage: `url(${require("@/assets/sherlock.png")}),url(${require("@/assets/coming_soon_bg.jpg")})`,
        backgroundPosition: "right top,center",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundSize: "auto 100%,cover",
        "-webkit-animation": "fadein 2s" /* Safari, Chrome and Opera > 12.1 */,
        "-moz-animation": "fadein 2s" /* Firefox < 16 */,
        "-ms-animation": "fadein 2s" /* Internet Explorer */,
        "-o-animation": "fadein 2s" /* Opera < 12.1 */,
        animation: "fadein 2s"
      }
    };
  }
};
</script>

<style lang="scss">
@import "scss/_custom.scss";
@import "~bootstrap/scss/bootstrap.scss";

html,
body,
#app {
  height: 100%;
  margin: 0;
  font-family: Roboto Condensed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
